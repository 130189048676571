import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Container from "reactstrap/lib/Container";
import { Button, Img } from "components/ui";
import { closeModal } from "store/modules/modal";

import "./age-modal.scss";

const AGE_CONFIRMED = "AGE_CONFIRMED";

const AgeModal = React.memo(props => {
    const { dispatch } = props;

    const handleCancel = () => {
        window.location.replace("https://google.com");
    };

    const handleSubmit = () => {
        try {
            localStorage.setItem(AGE_CONFIRMED, "true");
        } catch (err) {
            return;
        } finally {
            dispatch(closeModal());
        }
    };

    return (
        <div className="age__modal">
            <Container>
                <Img alt="" src="images/budza.png" />
                <h2>
                    Are you at least 21 years old or a valid medical marijuana
                    patient and agree to the Terms of Use and Privacy Policy?
                </h2>
                <div className="cta">
                    <Button className="cta-btn" onClick={handleSubmit}>
                        Yes, I’m 21 or older
                    </Button>
                    <Button outline onClick={handleCancel}>
                        No, Exit the website
                    </Button>
                </div>
            </Container>
            <div className="circles">
                <i className="c1" />
                <i className="c2" />
                <i className="c3" />
                <i className="c4" />
                <i className="c5" />
                <i className="c6" />
                <i className="t1" />
                <i className="t2" />
            </div>
        </div>
    );
});

AgeModal.propTypes = {
    dispatch: PropTypes.func.isRequired
};
const mapStateToProps = ({ general }) => ({
    general: general.general
});
export default connect(mapStateToProps)(AgeModal);
